import {
  Box,
  Button,
  HStack,
  Input,
  VStack,
} from "@chakra-ui/react";
import { CategoriesFilter } from "./categoriesFilter";
import { TypeCategory, TypeQuerySearch } from "../../config/categories";
import { useEffect, useState } from "react";

interface FiltersProps {
  callbackSearch(cat: TypeCategory[], keywords: string): void;
  querySearch: TypeQuerySearch;
  excludeCategories: string;
}

export function Filters(props: FiltersProps) {
  const [selectedCategories, setselectedCategories] = useState<TypeCategory[]>(
    []
  );
  const [keywordsSearch, setKeywordsSearch] = useState("");

  useEffect(() => {
    setselectedCategories(props.querySearch.categories);
    return () => {};
  }, [props.querySearch.categories]);

  const doSearch = () => {
    props.callbackSearch(selectedCategories, keywordsSearch);
    // setKeywordsSearch("");
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeywordsSearch(event.target.value);
  };

  return (
    <Box>
      <VStack
        direction={{
          base: "row",
          sm: "row",
          md: "row",
          lg: "row",
          xl: "row",
        }}
        isInline={false}
      >
        <CategoriesFilter
          currentFilters={selectedCategories}
          setCurrentFilters={setselectedCategories}
          excludeCategories={props.excludeCategories}
        />
        <Box></Box>
        <Box>
          <HStack>
            <Input
              value={keywordsSearch}
              display="inline"
              placeholder="Cerca per parola chiave"
              onChange={handleChange}
            ></Input>
            <Button w="100%"  onClick={doSearch} >
              Applica filtri
            </Button>
          </HStack>
        </Box>
      </VStack>
    </Box>
  );
}
