/**
 * CATEGORIE
 */
export const categoryList:TypeCategory[] = [
  { key: "24773", value: "Casa e Design" },
  { key: "24772", value:  "Sport e Hobby"},
  { key: "24776", value:  "Esperienze e Servizi"},
  { key: "24775", value:  "Hi-Tec"},
  { key: "24774", value:  "Gourmet"},
  { key: "24771", value:  "Per Lui"},
  { key: "24711", value:  "Per Lei"},
  { key: "76809", value:  "Bambini"},
  { key: "598752", value:  "Exclusive"},
  { key: "595316", value:  "Buoni"},
  { key: "617030", value:  "Amazon Gift Card"},
  { key: "857522", value:  "Professional"},
];

export type TypeCategory = {
  key: string,
  value: string
}

export type TypeQuerySearch = {
  categories: TypeCategory[];
  keyword: string;
}