const Button = {
  // The styles all button have in common
  baseStyle: {
    fontWeight: "bold",
    textTransform: "uppercase",
    borderRadius: "base", // <-- border radius is same for all variants and sizes
  },
  // Two sizes: sm and md
  sizes: {
    sm: {
      fontSize: "sm",
      px: 4, // <-- px is short for paddingLeft and paddingRight
      py: 3, // <-- py is short for paddingTop and paddingBottom
    },
    md: {
      fontSize: "md",
      px: 6, // <-- these values are tokens from the design system
      py: 4, // <-- these values are tokens from the design system
    },
  },
  // Two variants: outline and solid
  variants: {
    outline: {
      border: "2px solid",
      borderColor: "wishlist.400",
      color: "wishlist.300",
    },
    solid: {
      bg: "wishlist.500",
      color: "white",
      _hover: {
        bg: "wishlist.600",
      },
    },
    ghost: {
      bg: "wishlist.100",
      color: "#fff",
    },
    notselected: {
      fontSize: "0.8em",
      bg: "#fff",
      border: "1px solid",
      borderColor: "wishlist.100",
      color: "wishlist.300",
    },
    selected: {
      fontSize: "0.8em",
      bg: "wishlist.300",
      border: "none",
      color: "#fff",
    },
    link: {      
      bg: "#fff",
      fontSize: "0.9em",
      fontWeight: "normal",
      color: "wishlist.500",
      _hover: {
        textDecoration: "none",
        bg: "#fff",      
        color: "wishlist.500",
        fontWeight: "bold",
      }
    }
  },
  // The default size and variant values
  defaultProps: {
    size: "md",
    variant: "solid",
  },
};

export default Button;
