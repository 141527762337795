import { categoryList } from "./categories";

// export const bconf = {
//   BASE_URL: "https://shopcrm.wishlist.it/api/",
//   USERNAME: "Wishlist2021",
//   IDPARTNER: "2555205",
//   IMAGE_URL: "https://shopcrm.wishlist.it/crm/storage/images/products/product_info/",
//   LOGIN: "https://shop.wishlist.it/wp-content/plugins/wl-api/wlt.php",
//   CATEGORIES: categoryList,
//   totalProductPerPage: 15
// };

export const bconf = {
  BASE_URL: "https://www.wishlist.it/api/",
  USERNAME: "Wishlist2021",
  IDPARTNER: "2555205",
  IMAGE_URL: "https://www.wishlist.it/crm/storage/images/products/product_info/",
  LOGIN: "https://shop.wishlist.it/wp-content/plugins/wl-api/wlt.php",
  CATEGORIES: categoryList,
  totalProductPerPage: 15
};



