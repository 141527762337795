import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import CSSReset from "./theme/cssreset";
import wishlistTheme from "./theme/wishlistTheme";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "chakra-scope": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

//FIXME: Add Sentry



const target = document.getElementById("wlcat-root");

if (target) {
  const collectionProps = {
    idcollection: target.getAttribute("idcollection")
      ? String(target.getAttribute("idcollection"))
      : "",
    excludeCategories: target.getAttribute("exclude_categories")
      ? String(target.getAttribute("exclude_categories"))
      : "",
  };
  

  ReactDOM.render(
    <React.StrictMode>
     
      <ChakraProvider resetCSS={false} theme={wishlistTheme} >
      <CSSReset />
        <App {...collectionProps} />
      </ChakraProvider>
    </React.StrictMode>,
    target
  );
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
