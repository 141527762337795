import { Button, Box, Text, Divider } from "@chakra-ui/react";
import * as React from "react";
import { useState } from "react";
import { TypeCategory } from "../../config/categories";
import { bconf } from "../../config/config";
import { FiltersCategoriesProps } from "./filterInterfaces";

export const CategoriesFilter = ({
  currentFilters,
  setCurrentFilters,
  excludeCategories,
}: FiltersCategoriesProps) => {
  const [categories] = useState(doExcludeCategories(excludeCategories));

  const clickSelect = (e: React.MouseEvent<HTMLButtonElement>) => {
    const value = e.currentTarget.value;
    const text = e.currentTarget.innerText;
    e.preventDefault();

    const targetIndex = currentFilters.findIndex(
      (each: TypeCategory) => each.key === value
    );

    if (targetIndex !== -1) {
      if (setCurrentFilters)
        setCurrentFilters(
          currentFilters.filter((element) => element.key !== value)
        );
    } else {
      if (setCurrentFilters)
        setCurrentFilters((prev) => [...prev, { key: value, value: text }]);
    }
  };
  return (
    <Box id="categoriesContainer" >
      <Text
        textAlign={{
          sm: "center",
          md: "right",
          lg: "right",
          xl: "right",
        }}
        fontWeight="semibold"
        color="wishlist.500"
      >
        {" "}
        Categorie selezionate: {currentFilters.length}
      </Text>
      <Divider color="wishlist.500" />

      {categories.map((category) => (
        <Button
          h="2em"
        
          variant={
            currentFilters.find((el) => el.key === category.key)
              ? "selected"
              : "notselected"
          }
          m="5px"
          value={category.key}
          onClick={clickSelect}
          key={category.key}
          colorScheme="wishlist"
          _hover={{
            background: "wishlist.300",
            color: "#fff",
          }}
          
        >
          {category.value}
        </Button>
        
      ))}
    </Box>
  );
};

const doExcludeCategories = (arrExcludeCategories: string) => {
  const filteredCategories = bconf.CATEGORIES.filter((element) => {
    return !arrExcludeCategories.includes(element.key);
  });
  return filteredCategories;
};
