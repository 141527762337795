import { TriangleDownIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Center, SimpleGrid,
  Spinner
} from "@chakra-ui/react";
import { DisplayError } from "../components/error";
import { Filters } from "../components/menuFilters/filters";
import { Paginator } from "../components/paginator";
import ProductElement from "../components/product";
import { TypeCategory } from "../config/categories";
import { bconf } from "../config/config";
import { useFetchProducts } from "../hooks/useProducts";
import { ProductProps } from "../types/types";

function PageMainList(props: mainListProps) {
  const {
    result: { isLoading, isError, data, isSuccess, error },
    currentPage,
    setCurrentPage,
    querySearch,
    setquerySearch,
  } = useFetchProducts(props.idcollection, props.excludeCategories);
  
  const doSearch = (
    selectedCat: TypeCategory[],
    selectedKeywords: string
  ): void => {
    setquerySearch({
      categories: selectedCat,
      keyword: selectedKeywords,
    });

    setCurrentPage(1);
  };

  return (
    <Box className="App" id="grid-result" p="1em">
     
      <Accordion allowMultiple border="none">
        <AccordionItem border="none">
          <AccordionButton
            bg="wishlist.50"
            color="wishlist.500"
            border="1px solid"
            borderColor="wishlist.200"
            _hover={{
              bg: "wishlist.100",
              border: "1px solid",
              borderColor: "wishlist.200",
            }}
          >
            <Box flex="1" textAlign="center">
              Filtri di ricerca
            </Box>
            <TriangleDownIcon />
          </AccordionButton>

          <AccordionPanel pb={4} border="1px solid" borderColor="wishlist.200">
            <Filters
              callbackSearch={doSearch}
              querySearch={querySearch}
              excludeCategories={props.excludeCategories}
            />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      {isLoading && (
        <Center minH="xl" minW="xl">
          <Spinner
            thickness="5px"
            speed="0.65s"
            emptyColor="gray.200"
            color="puple.800"
            size="xl"
          />
        </Center>
      )}
      {isError && error && error instanceof Error && (
        <DisplayError error={error} />
      )}
      {isSuccess && (
        <Paginator
          currentPage={currentPage}
          totalPages={
            data?.data
              ? Math.ceil(data.data.total / bconf.totalProductPerPage)
              : 0
          }
          setCurrentPage={setCurrentPage}
        />
      )}
      <Center>
        <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} spacing={5}>
          {isSuccess &&
            data &&
            data.data.results.map((element: ProductProps) => {
              return <ProductElement {...element} key={element.id} />;
            })}
        </SimpleGrid>
      </Center>
      {isSuccess && (
        <Paginator
          currentPage={currentPage}
          totalPages={
            data?.data
              ? Math.ceil(data.data.total / bconf.totalProductPerPage)
              : 0
          }
          setCurrentPage={setCurrentPage}
        />
      )}
    </Box>
  );
}

export default PageMainList;

export interface mainListProps {
  idcollection: string;
  excludeCategories: string;
}
