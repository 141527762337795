import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import { Box, Center, HStack, StackDivider } from "@chakra-ui/react";
import * as React from "react";

export interface PaginatorProps {
  totalPages: number;
  currentPage: number;
  setCurrentPage: any;
}

const gotogrid = "#grid-result";

export const Paginator = (props: PaginatorProps) => {
  const next = () => {
    props.setCurrentPage(props.currentPage + 1);
    window.location.href = gotogrid;
  };
  const previous = () => {
    if (props.currentPage > 1) props.setCurrentPage(props.currentPage - 1);
    window.location.href = gotogrid;
  };

  return (
    <Center color="wishlist.500">
      <HStack
        divider={<StackDivider borderColor="wishlist.300" />}
        spacing={4}
        align="stretch"
      >
        <Center>
          {props.totalPages > 1 && props.currentPage > 1 && (
            // <Button onClick={previous} variant="link"  bg="none" border="none" colorScheme="wishlist">
            //   Precedente
            // </Button>
            <ArrowLeftIcon onClick={previous} />
          )}

          <Box p="1em" fontSize="1.1em" >
            Pagina {props.currentPage} di {props.totalPages}
          </Box>
          {props.currentPage < props.totalPages && (
            // <Button onClick={next} variant="link" bg="none" border="none"  colorScheme="wishlist">
            //   Successivo
            // </Button>
            <ArrowRightIcon onClick={next} />
          )}
        </Center>
      </HStack>
    </Center>
  );
};
