import { extendTheme } from "@chakra-ui/react";
import Button from "./button";
import colors from "./colors";

const overrides = {
  colors: colors,
  components: {
    Button,
  },
};
export default extendTheme(overrides);
