import React from "react";
import { Box, Badge, Image, Center, VStack } from "@chakra-ui/react";
import { ProductProps } from "../types/types";
import { bconf } from "../config/config";
import ModalDetailProduct from "./modalDetailProduct";

const ProductElement = (product: ProductProps) => {
  return (
    <Box
      paddingRight="1em"
      paddingLeft="1em"
      maxW="sm"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      borderStyle="solid"
      color="wishlist.400"
    >
      <Box
        mt="1"
        fontWeight="semibold"
        fontSize="1.3em"
        as="h4"
        lineHeight="normal"
        color="wishlist.500"
        textTransform="none"
      >
        {product.productname}
      </Box>
      <Center>
        <Image
          boxSize="sm"
          src={`${bconf.IMAGE_URL}${product.imagename}`}
          alt={product.descrizione}
        />
      </Center>

      <Box p="6">
        <VStack>
          <Badge
            m="0.5em"
            borderRadius="base"
            variant="subtle"
            backgroundColor="#fff"
            border="1px solid"
            padding="0.3em"
            borderColor="wishlist.400"
            fontSize="medium"
            fontWeight="bold"
            color="wishlist.400"
          >
            {product.brand}
          </Badge>
          <Badge
            m="0.5em"
            borderRadius="base"
            variant="subtle"
            color="wishlist.400"
            fontWeight="normal"
            backgroundColor="#fff"
          >
            {product.product_categories}
          </Badge>
        </VStack>

        <Box color="gray.500" noOfLines={2} marginTop="1em" marginBottom="1em">
          {product.descrizione}
        </Box>
        <ModalDetailProduct {...product} />
      </Box>
    </Box>
  );
};

export default ProductElement;
