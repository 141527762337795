import { TypeQuerySearch } from './../config/categories';
import { useQuery } from "react-query";
import axios from "axios";
import { renewToken } from "../api/genToken";
import { bconf } from "../config/config";
import { useState } from "react";

export const useFetchProducts = (
  collection: string,
  excludeCategories: string
) => {
  const [currentPage, setCurrentPage] = useState(1);
  

  const [querySearch, setquerySearch] = useState<TypeQuerySearch>({
    categories: [],
    keyword: ""
  })
  

  const result = useQuery(
    [
      "products",
      { collection, currentPage, querySearch },
    ],
    async () => {
      const url = bconf.BASE_URL;
      const user = bconf.USERNAME;
      const cat = querySearch.categories.map((elem) => elem.key).join(",");
     
      return await axios({
        method: "get",
        url: `${url}Collection?sessiontoken=${await renewToken()}&idcollection=${collection}&username=${user}&offset=${bconf.totalProductPerPage}&page=${currentPage}&categories=${cat}&filter=${querySearch.keyword}&categories_to_exclude=${excludeCategories}&timestamp=${new Date().getTime()}`,
      });
    }
  );
  return {
    result,
    currentPage,
    setCurrentPage,
    querySearch,
    setquerySearch
  };
};
