const colors = 
   {
    transparent: "transparent",
    black: "#000",
    white: "#fff",
    wishlist: {
      50: "#fcecfc",
      100: "#e8cbe8",
      200: "#d5aad5",
      300: "#c488c3",
      400: "#b267b2",
      500: "#984e98",
      600: "#773c77",
      700: "#562b56",
      800: "#351935",
      900: "#160616",
    },
  
};

export default colors;
