import axios from "axios";
import { bconf } from "../config/config";

// if (sessionStorage.getItem('sessionToken')) endpoint += '&sessiontoken=' + sessionStorage.getItem('sessionToken');
export const generateToken = async (): Promise<string> => {
  const now = new Date();
  const url = bconf.LOGIN + '?s=' + String(now.getTime());

  try {
    
    const response = await axios({
      method: "get",
      url: url,
    });

    if (response.status === 200 && response.data) {
      return response.data;
    } else {
      throw new Error(`Fail: HTTP ${response.status}`);
    }
  } catch (error) {
    throw error;
  }
};

export async function renewToken() {
  // let tcookie = await sessionStorage.getItem("st");
  let tcookie = await getWithExpiry("st");

  if (tcookie === undefined || tcookie === "" || tcookie === null || tcookie === "Not authorized") {
    tcookie = await generateToken();

    // await sessionStorage.setItem("st", tcookie);
    await setWithExpiry("st", tcookie, 60000);
    if (process.env.NODE_ENV !== "production") console.log(tcookie);
  }
  return tcookie;
}

const setWithExpiry = async (key: string, value: string, ttl: number) => {
  const now = new Date();

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

const getWithExpiry = async (key: string) => {
  const itemStr = localStorage.getItem(key);
  let item = { value: "", expiry: 0 };
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  try {
    item = JSON.parse(itemStr);
  } catch (error) {
    return null;
  }

  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};