import React from "react";
import "./App.css";
import { QueryClient, QueryClientProvider } from "react-query";
import PageMainList from "./pages/pageMainList";
import { mainListProps } from "./pages/pageMainList";
import { ReactQueryDevtools } from "react-query/devtools";

// Create a client
const queryClient = new QueryClient();

function App(shortcodesetting: mainListProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <PageMainList {...shortcodesetting} />
      <ReactQueryDevtools initialIsOpen={true} />
    </QueryClientProvider>
  );
}

export default App;
