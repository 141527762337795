import React from "react";
import {
  useDisclosure,
  Image,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Badge,
  Box,
  VStack,
  Divider,
  Center,
} from "@chakra-ui/react";
import { ProductProps } from "../types/types";
import { bconf } from "../config/config";

const ModalDetailProduct = (product: ProductProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button onClick={onOpen}>Dettagli</Button>

      <Modal
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        scrollBehavior="inside"
        size="3xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <VStack>
              <Box>{product.productname}</Box>
              <Box>
               
                  <Badge
                    m="0.5em"
                    borderRadius="base"
                    variant="subtle"
                    backgroundColor="#fff"
                    border="1px solid"
                    padding="0.3em"
                    borderColor="wishlist.400"
                    fontSize="medium"
                    fontWeight="bold"
                    color="wishlist.400"
                  >
                    {product.brand}
                  </Badge>
                  <Badge
                    m="0.5em"
                    borderRadius="base"
                    variant="subtle"
                    color="wishlist.400"
                    fontWeight="normal"
                    backgroundColor="#fff"
                  >
                    {product.product_categories}
                  </Badge>
               
              </Box>
            </VStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Divider orientation="horizontal" />
            <VStack>
              <Center>
                <Image
                  src={`${bconf.IMAGE_URL}${product.imagename}`}
                  alt={product.descrizione_lunga}
                />
              </Center>
              <Divider orientation="horizontal" marginBottom="1em" />
              <Box>{product.descrizione_lunga}</Box>
            </VStack>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalDetailProduct;
